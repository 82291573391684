function errorHandler(err) {
  let message = "";

  switch (err.code) {
    case "auth/email-already-in-use":
      message = "Diese E-Mail wird bereits verwendet.";
      break;
    case "auth/user-not-found":
      message = "Diese E-Mail wurde nicht gefunden";
      break;
    case "auth/invalid-email":
      message = "Bitte geben Sie eine gültige E-Mail an.";
      break;
    case "auth/wrong-password":
      message = "Die E-Mail oder das Passwort sind ungültig.";
      break;
    default:
      message = "Es ist etwas schief gelaufen.";
      break;
  }
  return message;
}

export { errorHandler };
