<template>
  <div class="px-5">
    <div class="relative w-full">
      <div class="absolute inset-0">
        <img
          class="object-cover w-full shadow-xl filter blur-sm"
          style="height: 700px"
          src="../assets/Bilder/csm_teaser-kinderrechte_ac1bb199c0.jpg.pagespeed.ce.fjyMrPmKKE.jpg"
          alt=""
        />
      </div>
      <div class="py-36">
        <Form class="relative" />
      </div>
    </div>
  </div>
</template>

<script>
// import { ref } from "vue";
import Form from "../components/login/form.vue";

export default {
  components: { Form },
  setup() {},
};
</script>
