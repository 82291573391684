<template>
  <form
    class="
      space-y-6
      form
      bg-white
      py-8
      px-4
      shadow-lg
      sm:px-10
      mx-auto
      w-full
      md:max-w-md
    "
    ref="form"
    @submit.prevent="login"
  >
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
      <h2
        class="
          mt-10
          text-5xl text-center
          uppercase
          text-brand-red
          font-amatic-sc
        "
      >
        melden sie sich bitte an
      </h2>
    </div>
    <Notification :notification="notification" />
    <input
      placeholder="E-Mail Adresse"
      v-model="email"
      id="email"
      name="email"
      type="email"
      autocomplete="email"
      required
      class="
        appearance-none
        block
        w-full
        px-3
        py-2
        border border-gray-300
        shadow-lg
        placeholder-gray-400
        focus:outline-none focus:ring-gray-500 focus:border-gray-500
        sm:text-sm
      "
    />

    <input
      placeholder="Passwort"
      v-model="password"
      id="password"
      name="password"
      type="password"
      autocomplete="current-password"
      required
      class="
        appearance-none
        block
        w-full
        px-3
        py-2
        border border-gray-300
        shadow-lg
        placeholder-gray-400
        focus:outline-none focus:ring-gray-500 focus:border-gray-500
        sm:text-sm
      "
    />

    <div class="flex gap-4 select-none">
      <div>
        <input
          id="check-pp"
          name="check-pp"
          v-model="checkPP"
          type="checkbox"
          required
          class="cursor-pointer"
        />
      </div>
      <label
        for="check-pp"
        class="block text-sm font-medium text-gray-700 cursor-pointer"
      >
        <a
          href="https://www.kinderrechte-kongress.de/impressum"
          target="_blank"
          class="hover:underline"
        >
          Datenschutz
        </a>
        akzeptieren
      </label>
    </div>

    <button
      type="submit"
      @click="checkInput()"
      :disabled="!!loading"
      class="
        justify-center
        flex
        py-2
        px-2
        w-full
        rounded-md
        border border-transparent
        bg-brand-blue
        focus:ring-brand-blue
        text-white
        uppercase
        focus:outline-none focus:ring-2 focus:ring-offset-2
      "
    >
      <Loadcircle v-if="loading" />
      einloggen
    </button>
  </form>
</template>

<script>
import { ref } from "vue";
import firebase from "firebase";
import router from "@/router";
import Notification from "../notification.vue";
import Loadcircle from "../svg/loadcircle.vue";
import { errorHandler } from "../../errorHandler";

export default {
  components: { Notification, Loadcircle },
  setup: function () {
    const email = ref("");
    const password = ref("");
    const checkPP = ref(false);
    const loading = ref(false);
    const notification = {
      success: ref(false),
      info: ref(false),
      infoMessage: ref(""),
    };

    function checkInput() {
      notification.info.value = false;
      loading.value = true;

      let message = "";

      if (!email.value) {
        message = "Bitte geben Sie eine gültige E-Mail an.";
      }
      if (!password.value) {
        message = "Bitte geben Sie ein gültiges Passwort an.";
      }
      if (!checkPP.value) {
        message = "Bitte akzeptieren Sie den Datenschutz.";
      }
      if (!email.value && !password.value && !checkPP.value) {
        message =
          "Bitte füllen Sie alle Felder aus und akzeptieren Sie den Datenschutz.";
      }

      setNotification(false, message);

      notification.info.value = true;
      loading.value = false;
    }

    async function login() {
      notification.info.value = false;
      loading.value = true;
      try {
        await firebase
          .auth()
          .signInWithEmailAndPassword(email.value, password.value);

        setNotification(
          true,
          "Sie haben sich erfolgreich angemeldet und werden in kürze weitergeleitet."
        );
        setTimeout(() => {
          router.replace({ name: "Home" }).catch((err) => {
            console.log(err);
          });
        }, 1500);
      } catch (e) {
        setNotification(false, errorHandler(e));
      }
      notification.info.value = true;
      loading.value = false;
    }

    function setNotification(state, message) {
      notification.success.value = state;
      notification.infoMessage.value = message;
    }

    return {
      email,
      password,
      checkPP,
      loading,
      notification,
      checkInput,
      login,
    };
  },
};
</script>
